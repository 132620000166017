import { FC, Fragment } from "react";

import { usePDFViewer } from "./use-pdf-viewer";
import { PDFOptionsBar } from "./pdf-options-bar";
import { VirtualizedPDF } from "./virtualized-pdf";
import { SecDocument } from "./context/types";
import {useMultiplePdf} from "./use-multiple-pdf";

interface PdfViewerProps {
  files: SecDocument[];
}

export const PdfViewer: FC<PdfViewerProps> = ({ files = [] }) => {

  const {
    activeDoc,
    activeDocIndex,
    goToNextDoc,
    goToPreviousDoc,
    numDocs
  } = useMultiplePdf(files.slice(0, 3))

  const {
    scrolledIndex,
    setCurrentPageNumber,
    scale,
    setScaleFit,
    numPages,
    setNumPages,
    handleZoomIn,
    handleZoomOut,
    nextPage,
    prevPage,
    scaleText,
    pdfFocusRef,
    goToPage,
    setZoomLevel,
    zoomInEnabled,
    zoomOutEnabled,
  } = usePDFViewer(activeDoc, activeDoc.page);

  return (
    <Fragment>
      {scaleText && (
        <PDFOptionsBar
          scrolledIndex={scrolledIndex}
          numPages={numPages}
          numDocs={numDocs}
          activeDocNumber={activeDocIndex + 1}
          scaleText={scaleText}
          zoomInEnabled={zoomInEnabled}
          zoomOutEnabled={zoomOutEnabled}
          nextPage={nextPage}
          prevPage={prevPage}
          handleZoomIn={handleZoomIn}
          handleZoomOut={handleZoomOut}
          goToPage={goToPage}
          goToNextDoc={goToNextDoc}
          goToPreviousDoc={goToPreviousDoc}
          setZoomLevel={setZoomLevel}
        />
      )}

      <VirtualizedPDF
        ref={pdfFocusRef}
        file={activeDoc}
        page={activeDoc.page}
        scale={scale}
        setIndex={setCurrentPageNumber}
        setScaleFit={setScaleFit}
        setNumPages={setNumPages}
      />
    </Fragment>
  );
};
