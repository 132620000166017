import { useState, useEffect, useRef, FC } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { PdfViewer } from "./components/pdf-viewer";
import { SecDocument } from "./components/pdf-viewer/context/types";
import { PdfFocusProvider } from "./components/pdf-viewer/context/pdf";
import { Answer } from "./components/answer";
import { fetchConversationId } from "../../api/backend";
import { BACKEND_URL } from "../../api/constants";
import { PATHS } from "../../components/routes/paths";
import { getHeaders } from "../../utils/get-headers";
import { categories } from "./constants";
import { DocData, Message, ReceivedMessage} from "./types";
import { Input } from "./components/input";
import { cn } from "../../utils/tw-merge";

import styles from "./home.module.css";
import { CircularProgress } from "@mui/material";

interface LoginProps {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
}

export const Home: FC<LoginProps> = ({ isAuthenticated, setIsAuthenticated }) => {
  const navigate = useNavigate();

  const messagesRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [conversationId, setConversationId] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedDocuments, setSelectedDocuments] = useState<SecDocument[]>([]);
  const [categoryName, setCatregoryName] = useState("ODA");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { authenticated, conversationId: fetchedConversationId } = await fetchConversationId();
        if (!authenticated) {
          navigate(PATHS.LOGIN)
        } else {
          setConversationId(fetchedConversationId);
        }
        if (!isAuthenticated) {
          setIsAuthenticated(true)
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    messagesRef.current?.scrollTo(0, messagesRef.current.scrollHeight);
  }, [messages]);

  const setPartialMessage = (
    chunk: string,
    messageId: string,
    type: string,
    sources: string[] = [],
    docData: DocData[] = [],
  ) => {
    setMessages((prevMessages) => {
      let lastMessage = prevMessages[prevMessages.length - 1];
      if (prevMessages.length === 0 || !lastMessage.isUser) {
        return [
          ...prevMessages.slice(0, -1),
          {
            message: lastMessage.message + chunk,
            id: lastMessage.id,
            type: lastMessage.type,
            isUser: false,
            sources: lastMessage.sources
              ? [...lastMessage.sources, ...sources]
              : sources,
            docData: lastMessage.docData
              ? [...lastMessage.docData, ...docData]
              : docData,
          },
        ];
      }

      return [
        ...prevMessages,
        { message: chunk, id: messageId, type, isUser: false, sources, docData },
      ];
    });
  };

  function handleReceiveMessage(data: ReceivedMessage) {
    setIsLoading(false)
    if (data.answer) {
      setPartialMessage(data.answer, data.id, data.type);
    }

    if (data.docs) {
      const urls = data.docs.map((doc) => doc.url);
      const originalDocs = _.uniqBy(data.docs, 'id');
      const originalUrls = originalDocs.map((doc) => ({
        name: doc.name,
        originalUrl: doc.origina_url,
        pageToGo: doc.page,
      }));
      setPartialMessage("", "", "", urls, originalUrls);
      setSelectedDocuments(data.docs);
    }
  }

  const handleSendMessage = (message: string) => {
    setMessages((prevMessages) => [...prevMessages, { message, isUser: true }]);
    setSelectedDocuments([]);
    setIsLoading(true)
    fetch(`${BACKEND_URL}message`, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        question: message,
        conversation: conversationId,
        category: categoryName
      }),
    })
      .then((response) => response.json())
      .then(handleReceiveMessage)
        .catch(() => setIsLoading(false));
  };


  return (
    <PdfFocusProvider>
      <main className={styles.root}>
        <div className={styles.question}>
          <div className={styles.header} />

          <div ref={messagesRef} className={styles.messagesWrapper}>
            {messages.map((message, index) => (
              <div
                key={index}
                className={cn(styles.messages, message.isUser && styles.answer)}
              >
                <span>{message.message}</span>
                {!message.isUser && message.type !== "question" && (
                  <Answer key={message.id} answer={message} />
                )}
              </div>
            ))}
          </div>
          <div className={styles.categories}>
                {categories.map((category, index) => (
                  <button
                    key={index}
                    className={`${styles.category} ${
                      category.name === categoryName ? styles.active : ''
                    }`}
                    onClick={() => setCatregoryName(category.name)}
                  >
                    {category.name}
                  </button>
                ))}
              </div>
          <Input handleSendMessage={handleSendMessage} />
        </div>
        {selectedDocuments.length === 0 && isLoading && (
            <div className={styles.loaderContainer}>
              <CircularProgress/>
            </div>
        )}
        {!!selectedDocuments.length && (
            <div className={styles.pdf}>
            <PdfViewer files={selectedDocuments} />
          </div>
        )}
      </main>
    </PdfFocusProvider>
  );
};
