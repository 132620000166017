import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { PATHS } from "./paths";
import { Login } from "../../pages/login";
import { Home } from "../../pages/home";

interface AppRoutesProps {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
}

export const AppRoutes: FC<AppRoutesProps> = ({
  isAuthenticated,
  setIsAuthenticated,
}) => (
  <Routes>
    <Route
      path={PATHS.LOGIN}
      element={
        <Login
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
        />
      }
    />

    <Route path={PATHS.HOME} element={<Home isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />

    <Route path="*" element={<Navigate to={PATHS.HOME} />} />
  </Routes>
);
