// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input_root__1x9IS {

    position: absolute;

    bottom: 0px;

    box-sizing: border-box;

    display: flex;

    height: 2.5rem;

    width: 100%;

    border-top-width: 1px;

    --tw-border-opacity: 1;

    border-color: rgb(255 103 29 / var(--tw-border-opacity))
}

.input_input__c-6EK {

    height: 100%;

    width: calc(100% - 48px);

    padding-left: 0.25rem;

    padding-right: 0.25rem;

    font-family: lato
}

.input_button__-UuBE {

    height: 100%;

    width: 3rem;

    --tw-bg-opacity: 1;

    background-color: rgb(255 103 29 / var(--tw-bg-opacity))
}

.input_icon__AKKAd {

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/components/input/input.module.css"],"names":[],"mappings":"AACE;;IAAA,kBAA8E;;IAA9E,WAA8E;;IAA9E,sBAA8E;;IAA9E,aAA8E;;IAA9E,cAA8E;;IAA9E,WAA8E;;IAA9E,qBAA8E;;IAA9E,sBAA8E;;IAA9E;AAA8E;;AAI9E;;IAAA,YAAkD;;IAAlD,wBAAkD;;IAAlD,qBAAkD;;IAAlD,sBAAkD;;IAAlD;AAAkD;;AAIlD;;IAAA,YAA+B;;IAA/B,WAA+B;;IAA/B,kBAA+B;;IAA/B;AAA+B;;AAI/B;;IAAA,oBAAiB;;IAAjB;AAAiB","sourcesContent":[".root {\n  @apply absolute flex bottom-0 w-full h-10 border-t border-[#FF671D] box-border;\n}\n\n.input {\n  @apply w-[calc(100%-48px)] h-full px-1 font-[lato];\n}\n\n.button {\n  @apply w-12 h-full bg-[#FF671D];\n}\n\n.icon {\n  @apply text-white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `input_root__1x9IS`,
	"input": `input_input__c-6EK`,
	"button": `input_button__-UuBE`,
	"icon": `input_icon__AKKAd`
};
export default ___CSS_LOADER_EXPORT___;
