import { FC, KeyboardEvent, useEffect, useRef } from "react";
import { Search } from "@mui/icons-material";

import styles from "./input.module.css";

interface InputProps {
  handleSendMessage: (message: string) => void;
}

export const Input: FC<InputProps> = ({ handleSendMessage }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleSearchClick();
    }
  };

  const handleSearchClick = () => {
    const value = inputRef.current?.value;
    if (value) {
      handleSendMessage(value.trim());
      inputRef.current.value = "";
    }
  };

  return (
    <div className={styles.root}>
      <input
        ref={inputRef}
        className={styles.input}
        placeholder="Focused"
        onKeyDown={handleKeyDown}
      />
      <button className={styles.button} onClick={handleSearchClick}>
        <Search className={styles.icon} />
      </button>
    </div>
  );
};
