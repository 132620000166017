// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_root__OHFS\\+ {

    display: flex;

    align-items: center;

    justify-content: space-between;

    border-bottom-width: 2px;

    --tw-border-opacity: 1;

    border-bottom-color: rgb(255 103 29 / var(--tw-border-opacity));

    padding-top: 0.625rem;

    padding-bottom: 0.625rem;

    padding-left: 2rem;

    padding-right: 2rem
}

.header_button__J4OfG {

    --tw-border-opacity: 1 !important;

    border-color: rgb(255 103 29 / var(--tw-border-opacity)) !important;

    text-transform: uppercase;

    --tw-text-opacity: 1 !important;

    color: rgb(255 103 29 / var(--tw-text-opacity)) !important
}

.header_button__J4OfG:hover {

    border-color: #ff671d36 !important
}
`, "",{"version":3,"sources":["webpack://./src/components/header/header.module.css"],"names":[],"mappings":"AACE;;IAAA,aAAkF;;IAAlF,mBAAkF;;IAAlF,8BAAkF;;IAAlF,wBAAkF;;IAAlF,sBAAkF;;IAAlF,+DAAkF;;IAAlF,qBAAkF;;IAAlF,wBAAkF;;IAAlF,kBAAkF;;IAAlF;AAAkF;;AAIlF;;IAAA,iCAA4E;;IAA5E,mEAA4E;;IAA5E,yBAA4E;;IAA5E,+BAA4E;;IAA5E;AAA4E;;AAA5E;;IAAA;AAA4E","sourcesContent":[".root {\n  @apply py-2.5 px-8 border-b-2 border-b-[#ff671d] flex justify-between items-center;\n}\n\n.button {\n  @apply uppercase !text-[#ff671d] !border-[#ff671d] hover:!border-[#ff671d36];\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `header_root__OHFS+`,
	"button": `header_button__J4OfG`
};
export default ___CSS_LOADER_EXPORT___;
