// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_root__SqL1z {

    height: calc(100vh - 61px);

    --tw-bg-opacity: 1;

    background-color: rgb(209 215 221 / var(--tw-bg-opacity));

    padding-top: 10rem
}

.login_container__P0FSm {

    max-width: 32rem !important;

    border-radius: 0.75rem;

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity));

    padding: 0px !important
}

.login_title__O4MZp {

    margin-top: 0.75rem !important;

    margin-bottom: 2rem !important;

    font-size: 40px !important;

    font-weight: 600 !important;

    text-transform: uppercase
}

.login_button__IcZC9 {

    height: 60px !important;

    --tw-bg-opacity: 1 !important;

    background-color: rgb(255 103 29 / var(--tw-bg-opacity)) !important;

    font-size: 1rem !important;

    line-height: 1.5rem !important;

    --tw-shadow: 0 0 #0000 !important;

    --tw-shadow-colored: 0 0 #0000 !important;

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.login_button__IcZC9:hover {

    background-color: #ff671d99 !important
}
`, "",{"version":3,"sources":["webpack://./src/pages/login/login.module.css"],"names":[],"mappings":"AACE;;IAAA,0BAA8C;;IAA9C,kBAA8C;;IAA9C,yDAA8C;;IAA9C;AAA8C;;AAI9C;;IAAA,2BAAyC;;IAAzC,sBAAyC;;IAAzC,kBAAyC;;IAAzC,yDAAyC;;IAAzC;AAAyC;;AAIzC;;IAAA,8BAAwD;;IAAxD,8BAAwD;;IAAxD,0BAAwD;;IAAxD,2BAAwD;;IAAxD;AAAwD;;AAIxD;;IAAA,uBAA4E;;IAA5E,6BAA4E;;IAA5E,mEAA4E;;IAA5E,0BAA4E;;IAA5E,8BAA4E;;IAA5E,iCAA4E;;IAA5E,yCAA4E;;IAA5E;AAA4E;;AAA5E;;IAAA;AAA4E","sourcesContent":[".root {\n  @apply bg-[#d1d7dd] h-[calc(100vh-61px)] pt-40;\n}\n\n.container {\n  @apply bg-white rounded-xl !p-0 !max-w-lg;\n}\n\n.title {\n  @apply uppercase !mt-3 !mb-8 !text-[40px] !font-semibold;\n}\n\n.button {\n  @apply !bg-[#ff671d] hover:!bg-[#ff671d99] !shadow-none !h-[60px] !text-base;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `login_root__SqL1z`,
	"container": `login_container__P0FSm`,
	"title": `login_title__O4MZp`,
	"button": `login_button__IcZC9`
};
export default ___CSS_LOADER_EXPORT___;
