import { FC, KeyboardEvent, useEffect, useRef, useState } from "react";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  ZoomOut,
  ZoomIn,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";

import { zoomLevels } from "./use-pdf-viewer";
import { cn } from "../../../../utils/tw-merge";

import styles from "./pdf-viewer.module.css";

interface PDFOptionsBarProps {
  scrolledIndex: number;
  numPages: number;
  numDocs: number;
  activeDocNumber: number;
  scaleText: string;
  nextPage: () => void;
  prevPage: () => void;
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  goToPage: (n: number) => void;
  goToNextDoc: () => void;
  goToPreviousDoc: () => void;
  setZoomLevel: (percent: string) => void;
  zoomInEnabled: boolean;
  zoomOutEnabled: boolean;
}

export const PDFOptionsBar: FC<PDFOptionsBarProps> = ({
  scrolledIndex,
  numPages,numDocs, activeDocNumber,
  scaleText,
  nextPage,
  prevPage,
  handleZoomIn,
  handleZoomOut,
  goToPage, goToNextDoc, goToPreviousDoc,
  setZoomLevel,
  zoomInEnabled,
  zoomOutEnabled,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [zoomPopoverOpen, setZoomPopoverOpen] = useState(false);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = `${scrolledIndex + 1}`;
    }
  }, [scrolledIndex]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node | null)
      ) {
        setZoomPopoverOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleZoomSelection = (zoom: string) => {
    setZoomLevel(zoom);
    setZoomPopoverOpen(false);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "e" || e.key === "E") {
      e.preventDefault();
      return;
    }

    if (e.key === "Enter") {
      const value = Number(inputRef.current?.value);

      if (!isNaN(value) && value > 0) {
        goToPage(value - 1);
      }
    }
  };

  return (
    <div className={styles.pdfOptionsBarRoot}>
      <div className={styles.controllers}>
        <div>
          <button onClick={goToPreviousDoc} disabled={activeDocNumber === 1}>
            <KeyboardArrowLeft />
          </button>
          Document #{activeDocNumber} from {numDocs}
          <button onClick={goToNextDoc} disabled={activeDocNumber === numDocs}>
            <KeyboardArrowRight />
          </button>
        </div>
        <button onClick={prevPage} disabled={scrolledIndex === 0}>
          <KeyboardArrowUp
            className={cn(styles.icon, {
              [styles.disabled]: scrolledIndex === 0,
            })}
          />
        </button>
        <div>
          <input ref={inputRef} type="number" onKeyDown={handleKeyDown} />
        </div>
        <div>/ {numPages}</div>
        <button onClick={nextPage} disabled={scrolledIndex === numPages - 1}>
          <KeyboardArrowDown
            className={cn(styles.icon, {
              [styles.disabled]: scrolledIndex === numPages - 1,
            })}
          />
        </button>
      </div>
      <div className={styles.divider} />
      <div className={cn(styles.controllers, styles.zoomWrapper)}>
        <button onClick={handleZoomOut} disabled={!zoomOutEnabled}>
          <ZoomOut className={styles.icon} />
        </button>
        <div onClick={() => setZoomPopoverOpen(!zoomPopoverOpen)}>
          <div className={cn(styles.controllers, styles.percentSelector)}>
            <span>{scaleText}</span>
            {zoomPopoverOpen ? (
              <KeyboardArrowUp className={styles.icon} />
            ) : (
              <KeyboardArrowDown className={styles.icon} />
            )}
          </div>
        </div>
        {zoomPopoverOpen && (
          <div ref={popoverRef} className={styles.zoomPopover}>
            {zoomLevels.map((level) => (
              <button key={level} onClick={() => handleZoomSelection(level)}>
                {level}
              </button>
            ))}
          </div>
        )}
        <button onClick={handleZoomIn} disabled={!zoomInEnabled}>
          <ZoomIn className={styles.icon} />
        </button>
      </div>
    </div>
  );
};
