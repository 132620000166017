import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { useLocalStorage } from "@wojtekmaj/react-hooks";

import { Header } from "./components/header";
import { AppRoutes } from "./components/routes";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useLocalStorage(
    "isAuthenticated",
    false
  );

  const theme = createTheme({
    typography: {
      fontFamily: "Oswald",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header
        isAuthenticated={isAuthenticated}
        setIsAuthenticated={setIsAuthenticated}
      />
      <AppRoutes
        isAuthenticated={isAuthenticated}
        setIsAuthenticated={setIsAuthenticated}
      />
    </ThemeProvider>
  );
}

export default App;
