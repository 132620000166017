import {
  useState,
  useEffect,
  useRef,
  memo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { VariableSizeList as List } from "react-window";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import { Document, pdfjs } from "react-pdf";
import { CircularProgress } from "@mui/material";

import {
  HORIZONTAL_GUTTER_SIZE_PX,
  PAGE_HEIGHT,
  PDF_SIDEBAR_SIZE_PX,
  PDF_WIDTH_PERCENTAGE,
  VERTICAL_GUTTER_SIZE_PX,
} from "./pdf-display-constants";
import { SecDocument as PdfDocument, PdfType } from "./context/types";
import { PageRenderer } from "./page-renderer";

import styles from "./pdf-viewer.module.css";

import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

const pdfjsOptions = pdfjs.GlobalWorkerOptions;
const pdfjsVersion = pdfjs.version;
pdfjsOptions.workerSrc =
  "//unpkg.com/pdfjs-dist@" +
  String(pdfjsVersion) +
  "/legacy/build/pdf.worker.min.js";

interface VirtualizedPDFProps {
  file: PdfDocument;
  page: number;
  scale: number;
  setIndex: (n: number) => void;
  setScaleFit: (n: number) => void;
  setNumPages: (n: number) => void;
}
export interface PdfFocusHandler {
  scrollToPage: (page: number) => void;
}

const VirtualizedPDF = forwardRef<PdfFocusHandler, VirtualizedPDFProps>(
  ({ file, page, scale, setIndex, setScaleFit, setNumPages }, ref) => {
    const windowWidth = useWindowWidth();
    const newWidthPx =
      PDF_WIDTH_PERCENTAGE * 0.01 * (windowWidth || 0) -
      PDF_SIDEBAR_SIZE_PX -
      HORIZONTAL_GUTTER_SIZE_PX;

    const [pdf, setPdf] = useState<PdfType | null>(null);
    const listRef = useRef<List>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
      if (wrapperRef.current) {
        setHeight(wrapperRef.current.clientHeight - 20);
      }
    }, []);

    useEffect(() => {
      // Changing scale changes the measurement of the item, so we need to bust the cache, see:
      // https://github.com/bvaughn/react-window/issues/344#issuecomment-540583132
      if (listRef.current) {
        listRef.current.resetAfterIndex(0);
      }
    }, [scale]);

    function onDocumentLoadSuccess(nextPdf: PdfType) {
      setPdf(nextPdf);
      onItemClick({ pageNumber: page });
    }
    function getPageHeight(): number {
      const actualHeight = (PAGE_HEIGHT + VERTICAL_GUTTER_SIZE_PX) * scale;
      return actualHeight;
    }

    useEffect(() => {
      if (!pdf) {
        return;
      }
      async function loadFirstPage() {
        if (pdf) {
          await pdf
            .getPage(1)
            .then(
              (page: {
                getViewport: (arg0: { scale: number }) => { width: number };
              }) => {
                const pageViewport = page.getViewport({ scale: 1 });
                const pageWidth = pageViewport.width;
                const computedScaleFit = newWidthPx / pageWidth;
                // set scale to fit to page
                setScaleFit(computedScaleFit);
              }
            );
        }
      }
      loadFirstPage().catch(() => console.error("page load error"));
      setNumPages(pdf.numPages);
      pdf
        .getPage(1)
        .then(
          (pageItem: {
            getViewport: (arg0: { scale: number }) => { width: number };
          }) => {
            const pageViewport = pageItem.getViewport({ scale: 1 });
            const pageWidth = pageViewport.width;
            const computedScaleFit = newWidthPx / pageWidth;
            const fixedPosition =
              (page - 1) *
              (PAGE_HEIGHT + VERTICAL_GUTTER_SIZE_PX) *
              computedScaleFit;
            if (listRef.current) {
              listRef.current.scrollTo(fixedPosition);
            }
          }
        );
      // if (listRef.current) {
      //   const fixedPosition =
      //     page * (PAGE_HEIGHT + VERTICAL_GUTTER_SIZE_PX) * scale;
      //   listRef.current.scrollTo(fixedPosition);
      // }
    }, [pdf, file.id, setNumPages, setScaleFit, newWidthPx, page]);

    useImperativeHandle(ref, () => ({
      // This function can be called from the parent component
      scrollToPage: (page: number) => {
        onItemClick({ pageNumber: page });
      },
    }));

    const onItemClick = ({
      pageNumber: itemPageNumber,
    }: {
      pageNumber: number;
    }) => {
      const fixedPosition =
        itemPageNumber * (PAGE_HEIGHT + VERTICAL_GUTTER_SIZE_PX) * scale;
      if (listRef.current) {
        listRef.current.scrollTo(fixedPosition);
      }
    };

    const renderLoading = () => (
      <div className={styles.loader}>
        <CircularProgress />
      </div>
    );

    return (
      <div ref={wrapperRef} className={styles.virtualizedPdfRoot}>
        <Document
          key={file.url}
          file={file.url}
          onItemClick={onItemClick}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={renderLoading}
          error={renderLoading}
          className={styles.document}
        >
          {pdf && (
            <List
              ref={listRef}
              width="100%"
              height={height}
              itemCount={pdf.numPages}
              itemSize={getPageHeight}
              estimatedItemSize={
                (PAGE_HEIGHT + VERTICAL_GUTTER_SIZE_PX) * scale
              }
            >
              {({ index, style }) => (
                <PageRenderer
                  key={`page-${index}`}
                  file={file}
                  pageNumber={index}
                  style={style}
                  scale={scale}
                  listWidth={newWidthPx}
                  setPageInView={setIndex}
                />
              )}
            </List>
          )}
        </Document>
      </div>
    );
  }
);

const MemoizedVirtualizedPDF = memo(VirtualizedPDF);

export { MemoizedVirtualizedPDF as VirtualizedPDF };
