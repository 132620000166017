import {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Page } from "react-pdf";
import { useInView } from "react-intersection-observer";
import debounce from "lodash.debounce";

import { multiHighlight } from "./context/multi-line-highlight";
import { SecDocument as PdfDocument } from "./context/types";
import { usePdfFocus } from "./context/pdf";
import { OBSERVER_THRESHOLD_PERCENTAGE } from "./pdf-display-constants";

interface PageRendererProps {
  file: PdfDocument;
  pageNumber: number;
  style: CSSProperties;
  scale: number;
  listWidth: number;
  setPageInView: (n: number) => void;
}

export const PageRenderer: FC<PageRendererProps> = ({
  file,
  pageNumber,
  style,
  scale,
  listWidth,
  setPageInView,
}) => {
  const { pdfFocusState } = usePdfFocus();
  // const [shouldCenter, setShouldCenter] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const documentFocused = pdfFocusState.documentId === file.id;

  // Get which page is in view from an intersection observer
  const { ref: inViewRef, inView } = useInView({
    threshold: OBSERVER_THRESHOLD_PERCENTAGE * Math.min(1 / scale, 1),
  });

  // Prevents black flickering, which is fixed in 7.1.2, but we must
  // use 6.2.2 because highlights are broken in 7.1.2 :/
  // https://github.com/wojtekmaj/react-pdf/issues/1340#issuecomment-1483869537
  const containerRef = useRef<HTMLDivElement>(null);

  // Use `useCallback` so we don't recreate the function on each render
  // Need to set two Refs, one for the intersection observer, one for the container
  const setRefs = useCallback(
    (node: HTMLDivElement | null | undefined) => {
      // Ref's from useRef needs to have the node assigned to `current`
      (containerRef as React.MutableRefObject<HTMLDivElement | null>).current =
        node as HTMLDivElement | null;

      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node);
    },
    [inViewRef]
  );

  useEffect(() => {
    if (inView) {
      setPageInView(pageNumber);
    }
  }, [inView, pageNumber, setPageInView, inViewRef]);

  const togglePageCanvasState = useCallback(
    (visibility: "hidden" | "visible") => {
      if (containerRef.current) {
        const canvas = containerRef.current.querySelector("canvas");
        if (canvas) {
          canvas.style.visibility = visibility;
        }
      }
    },
    [containerRef]
  );

  const onPageRenderSuccess = useCallback(
    (page: { width: number }) => {
      togglePageCanvasState("visible");
      maybeHighlight();
      // react-pdf absolutely pins the pdf into the upper left corner
      // so when the scale changes and the width is smaller than the parent
      // container, we need to use flex box to center the pdf.
      //
      // why not always center the pdf? when this condition is not true,
      // display: flex breaks scrolling. not quite sure why.
      // if (listWidth > page.width) {
      //   setShouldCenter(true);
      // } else {
      //   setShouldCenter(false);
      // }
    },
    [togglePageCanvasState, listWidth]
  );

  useEffect(() => {
    maybeHighlight();
  }, [documentFocused, inView]);

  const maybeHighlight = useCallback(
    debounce(() => {
      if (
        documentFocused &&
        pdfFocusState.citation?.pageNumber === pageNumber + 1 &&
        !isHighlighted
      ) {
        multiHighlight(
          pdfFocusState.citation.snippet,
          pageNumber,
          pdfFocusState.citation.color
        );
        setIsHighlighted(true);
      }
    }, 50),
    [pdfFocusState.citation?.snippet, pageNumber, isHighlighted]
  );

  return (
    <div
      ref={setRefs}
      style={{
        ...style,
        // backgroundColor: "whitesmoke",
        // display: shouldCenter ? "flex" : "initial",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Page
        scale={scale}
        onRenderSuccess={onPageRenderSuccess}
        onLoadSuccess={() => togglePageCanvasState("hidden")}
        onRenderError={() => togglePageCanvasState("visible")}
        pageIndex={pageNumber}
        renderAnnotationLayer
      />
    </div>
  );
};
