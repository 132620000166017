import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import logo from "../../assets/logo.svg";

import styles from "./header.module.css";
import { PATHS } from "../routes/paths";
import { handleLogOut } from "../../api/backend";

interface HeaderProps {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
}

export const Header: FC<HeaderProps> = ({
  isAuthenticated,
  setIsAuthenticated,
}) => {
  const navigate = useNavigate();

  const handleLogOutClick = async () => {
    try {
      await handleLogOut();
      setIsAuthenticated(false);
      navigate(PATHS.LOGIN);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <header className={styles.root}>
      <img src={logo} alt="logo" />

      {isAuthenticated && (
        <Button
          className={styles.button}
          variant="outlined"
          onClick={handleLogOutClick}
        >
          Log out
        </Button>
      )}
    </header>
  );
};
