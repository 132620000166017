import { FC, useState } from "react";
import {
  ThumbDownOffAlt,
  ThumbUpOffAlt,
  ThumbDownAlt,
  ThumbUpAlt,
} from "@mui/icons-material";

import { Comment } from "./comment";
import { Message } from "../../types";
import { BACKEND_URL } from "../../../../api/constants";
import { getHeaders } from "../../../../utils/get-headers";

import styles from "./answer.module.css";

interface AnswerProps {
  answer: Message;
}

export const Answer: FC<AnswerProps> = ({ answer }) => {
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [showComment, setShowComment] = useState(false);

  const handleLikeClick = () => {
    if (!liked) {
      setLiked(true);
      setDisliked(false);
      setShowComment(false);
      handleSendReaction("like");
    }
  };

  const handleDislikeClick = () => {
    if (!disliked) {
      setDisliked(true);
      setLiked(false);
      setShowComment(true);
      handleSendReaction("dislike");
    }
  };

  const handleSendReaction = (type: "like" | "dislike") => {
    fetch(BACKEND_URL + `message`, {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify({
        id: answer.id,
        liked: type === "like",
      }),
    });
  };

  const formatSource = (source: string) => source.split("/").pop() || "";

  return (
    <div className={styles.root}>
      {answer.docData?.map((source, index) => (
        <div key={index} className={styles.source}>
          <span>{source.name}</span>
          <a
            target="_blank"
            download
            href={source.originalUrl}
            rel="noreferrer"
            className={styles.link}
          >
            Download file
          </a>
        </div>
      ))}
      {showComment && answer.id && (
          <Comment setShowComment={setShowComment} answerId={answer.id} />
      )}
      {!!answer.id && (
          <div className={styles.feedback}>
            <span>Did you find this answer helpful?</span>
            <button
                onClick={handleLikeClick}
                disabled={liked}
                className="ml-6 mr-3"
            >
              {liked ? (
                  <ThumbUpAlt className={styles.icon} />
              ) : (
                  <ThumbUpOffAlt className={styles.icon} />
              )}
            </button>
            <button onClick={handleDislikeClick} disabled={disliked}>
              {disliked ? (
                  <ThumbDownAlt className={styles.icon} />
              ) : (
                  <ThumbDownOffAlt className={styles.icon} />
              )}
            </button>
          </div>
      )}
    </div>
  );
};
