// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.answer_root__QiZJJ {

  margin-top: 0.75rem
}

.answer_source__IHABw {

  display: grid;

  justify-items: stretch
}

.answer_link__VC3cA {

  margin-bottom: 0.75rem;

  --tw-text-opacity: 1;

  color: rgb(0 61 166 / var(--tw-text-opacity));

  text-decoration-line: underline
}

.answer_feedback__3TPTI {

  justify-self: end
}

.answer_icon__Vs-my {

  fill: #FF671D !important
}

.answer_commentRoot__KKzb0 {

  margin-top: 0.5rem;

  margin-bottom: 0.5rem;

  display: grid;

  border-radius: 0.25rem;

  border-width: 1px;

  --tw-border-opacity: 1;

  border-color: rgb(255 103 29 / var(--tw-border-opacity));

  padding: 0.5rem;

  & textarea {

    margin-top: 0.5rem
  }

  & textarea {

    height: 5rem
  }

  & textarea {

    resize: none
  }

  & textarea {

    border-radius: 0.25rem
  }

  & textarea {

    padding: 0.5rem
  }

  .answer_button__by12n {

    margin-top: 0.5rem
  }

  .answer_button__by12n {

    border-radius: 0.25rem
  }

  .answer_button__by12n {

    --tw-bg-opacity: 1;

    background-color: rgb(255 103 29 / var(--tw-bg-opacity))
  }

  .answer_button__by12n {

    padding: 0.5rem
  }

  .answer_button__by12n {

    font-size: 1rem;

    line-height: 1.5rem
  }

  .answer_button__by12n {

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/components/answer/answer.module.css"],"names":[],"mappings":"AACE;;EAAA;AAAW;;AAIX;;EAAA,aAAiC;;EAAjC;AAAiC;;AAIjC;;EAAA,sBAAoC;;EAApC,oBAAoC;;EAApC,6CAAoC;;EAApC;AAAoC;;AAIpC;;EAAA;AAAuB;;AAIvB;;EAAA;AAAsB;;AAItB;;EAAA,kBAAoD;;EAApD,qBAAoD;;EAApD,aAAoD;;EAApD,sBAAoD;;EAApD,iBAAoD;;EAApD,sBAAoD;;EAApD,wDAAoD;;EAApD,eAAoD;;EAGlD;;IAAA;EAAwC;;EAAxC;;IAAA;EAAwC;;EAAxC;;IAAA;EAAwC;;EAAxC;;IAAA;EAAwC;;EAAxC;;IAAA;EAAwC;;EAIxC;;IAAA;EAAyD;;EAAzD;;IAAA;EAAyD;;EAAzD;;IAAA,kBAAyD;;IAAzD;EAAyD;;EAAzD;;IAAA;EAAyD;;EAAzD;;IAAA,eAAyD;;IAAzD;EAAyD;;EAAzD;;IAAA,oBAAyD;;IAAzD;EAAyD;AAPP","sourcesContent":[".root {\n  @apply mt-3;\n}\n\n.source {\n  @apply grid justify-items-stretch;\n}\n\n.link {\n  @apply text-[#003DA6] underline mb-3;\n}\n\n.feedback {\n  @apply justify-self-end;\n}\n\n.icon {\n  @apply !fill-[#FF671D];\n}\n\n.commentRoot {\n  @apply border rounded my-2 p-2 border-[#FF671D] grid;\n\n  & textarea {\n    @apply h-20 mt-2 p-2 rounded resize-none;\n  }\n\n  .button {\n    @apply mt-2 p-2 rounded bg-[#FF671D] text-white text-base;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `answer_root__QiZJJ`,
	"source": `answer_source__IHABw`,
	"link": `answer_link__VC3cA`,
	"feedback": `answer_feedback__3TPTI`,
	"icon": `answer_icon__Vs-my`,
	"commentRoot": `answer_commentRoot__KKzb0`,
	"button": `answer_button__by12n`
};
export default ___CSS_LOADER_EXPORT___;
