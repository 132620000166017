import { FC, FormEvent, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import { ErrorOutline, Visibility, VisibilityOff } from "@mui/icons-material";

import { BACKEND_URL } from "../../api/constants";
import unlock from "../../assets/unlock.svg";
import { PATHS } from "../../components/routes/paths";
import { getCSRFToken } from "../../utils/get-csrf-token";

import styles from "./login.module.css";

interface LoginProps {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
}

export const Login: FC<LoginProps> = ({
  isAuthenticated,
  setIsAuthenticated,
}) => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hasError, setHasError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        BACKEND_URL + "login/",
        { username, password },
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFTOKEN": getCSRFToken(),
          },
        }
      );

      if (response.status === 200) {
        setIsAuthenticated(true);
        setHasError(false);
        navigate(PATHS.HOME);
      } else {
        setHasError(true);
      }
    } catch (error) {
      setHasError(true);
    }
  };

  if (isAuthenticated) {
    return <Navigate to={PATHS.HOME} />;
  }

  return (
    <div className={styles.root}>
      <Container className={styles.container}>
        <Box
          sx={{
            padding: 7,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={unlock} alt="unlock" />
          <Typography className={styles.title}>Log in</Typography>
          {hasError && (
            <Alert
              variant="outlined"
              severity="error"
              sx={{
                mb: 3,
              }}
              className={styles.alert}
              icon={
                <ErrorOutline
                  sx={{
                    color: "red",
                  }}
                />
              }
            >
              <Typography
                color="red"
                sx={{
                  p: 0,
                }}
              >
                Wrong Username or Password
              </Typography>
            </Alert>
          )}
          <Box>
            <form onSubmit={handleSubmit}>
              <TextField
                InputLabelProps={{
                  required: false,
                }}
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />

              <TextField
                InputLabelProps={{
                  required: false,
                }}
                required
                fullWidth
                id="password"
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                sx={{ mt: 4 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                fullWidth
                variant="contained"
                type="submit"
                sx={{ mt: 4 }}
                className={styles.button}
              >
                Start your conversation
              </Button>
            </form>
          </Box>
        </Box>
      </Container>
    </div>
  );
};
