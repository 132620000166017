import { useEffect, useRef, useState } from "react";

import { usePdfFocus } from "./context/pdf";
import { SecDocument } from "./context/types";
import { PdfFocusHandler } from "./virtualized-pdf";

export const useMultiplePdf = (pdf: SecDocument[]) => {
  const [activePdfUrl, setActivePdfUrl] = useState<string>("");
  const [activeDoc, setActiveDoc] = useState<SecDocument>(pdf[0]);
  const [activeDocIndex, setActiveDocIndex] = useState<number>(0)
  const { pdfFocusState } = usePdfFocus();

  const pdfFocusRef = useRef<PdfFocusHandler | null>(null);
  const goToPage = (page: number) => {
    if (pdfFocusRef.current) {
      pdfFocusRef.current.scrollToPage(page);
    }
  };

  const goToNextDoc = () => {
    if (activeDocIndex <= pdf.length) {
      setActiveDoc(pdf[activeDocIndex + 1])
      setActiveDocIndex(activeDocIndex + 1)
    }
  }

  const goToPreviousDoc = () => {
    if (activeDocIndex > 0) {
      setActiveDoc(pdf[activeDocIndex - 1])
      setActiveDocIndex(activeDocIndex - 1)
    }
  }

  useEffect(() => {
    if (pdf && pdf[0]) {
      setActivePdfUrl(pdf[0].url);
    }
  }, [pdf]);

  useEffect(() => {
    if (pdfFocusState.documentId) {
      const selectedPdf = pdf.find(
        (doc) => doc.id === pdfFocusState.documentId
      );
      if (selectedPdf) {
        const selectedPdfIndex = pdf.findIndex((doc) => selectedPdf.id === doc.id)
        setActivePdfUrl(selectedPdf.url);
        setActiveDoc(selectedPdf)
        setActiveDocIndex(selectedPdfIndex)
      }
    }
  }, [pdfFocusState.pageNumber, pdfFocusState.documentId, setActivePdfUrl]);

  const isActivePdf = (file: SecDocument) => {
    return file.url === activePdfUrl;
  };

  const handlePdfFocus = (file: SecDocument, page: number) => {
    setActivePdfUrl(file.url);
    goToPage(page);
  };

  return {
    activePdfUrl,
    activeDoc,
    activeDocIndex,
    isActivePdf,
    handlePdfFocus,
    goToNextDoc,
    goToPreviousDoc,
    numDocs: pdf.length,
  };
};
