import axios from "axios";

import { BACKEND_URL } from "./constants";
import { getHeaders } from "../utils/get-headers";

export const handleSendMessage = async (
  message: string,
  category: string,
  conversationId: number
) => {
  const response = await fetch(BACKEND_URL + "message", {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      question: message,
      category: category,
      conversation: conversationId,
    }),
  });
  if (!response.ok) {
    throw new Error("Failed to send message");
  }
  return await response.json();
};

export const handleLogOut = async () => {
  const response = await fetch(BACKEND_URL + `logout/`, {
    method: "GET",
    headers: getHeaders(),
  });
  if (response.status === 200) {
    return true;
  } else {
    throw new Error("Failed to log out");
  }
};

export const handleFeedback = async (id: string, liked: boolean) => {
  const response = await fetch(BACKEND_URL + "message", {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify({
      id: id,
      liked: liked,
    }),
  });
  if (response.status === 200) {
    return true;
  } else {
    throw new Error("Failed to give feedback");
  }
};

export const handleLogin = async (username: string, password: string) => {
  try {
    const response = await axios.post(
      BACKEND_URL + "login/",
      { username, password },
      { headers: getHeaders() }
    );

    if (response.status === 200) {
      return { success: true, message: response.data.message };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error:", error);
    return {
      success: false,
      errorMessage: "An error occurred, please try again.",
    };
  }
};

export const fetchConversationId = async () => {
  try {
    const response = await fetch(BACKEND_URL + "message", {
      method: "GET",
      headers: getHeaders(),
    });

    const responseData = await response.json();

    if (responseData.message === "Not authenticated") {
      return { authenticated: false };
    } else {
      return { authenticated: true, conversationId: responseData.conversation };
    }
  } catch (error) {
    console.error("Error:", error);
    throw new Error("Failed to fetch conversation ID");
  }
};
