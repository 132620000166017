import { Dispatch, FC, SetStateAction, useRef } from "react";
import { Clear } from "@mui/icons-material";

import { BACKEND_URL } from "../../../../api/constants";
import { getHeaders } from "../../../../utils/get-headers";

import styles from "./answer.module.css";

interface CommentProps {
  setShowComment: Dispatch<SetStateAction<boolean>>;
  answerId: string;
}

export const Comment: FC<CommentProps> = ({ setShowComment, answerId }) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleSendMessage = () => {
    const comment = textAreaRef.current?.value.trim();

    if (!comment) {
      return;
    }

    setShowComment(false);
    fetch(BACKEND_URL + `message`, {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify({
        id: answerId,
        comment,
      }),
    });
  };

  return (
    <div className={styles.commentRoot}>
      <div className="justify-self-end">
        <button onClick={() => setShowComment(false)}>
          <Clear className={styles.icon} />
        </button>
      </div>
      <div>
        Didn’t like the answer? Please leave your comment so we can improve the
        quality of our product
      </div>
      <textarea ref={textAreaRef} placeholder="Enter your comment here..." />
      <button
        // className="mt-2 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
        className={styles.button}
        onClick={handleSendMessage}
      >
        Send
      </button>
    </div>
  );
};
